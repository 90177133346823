<template>
  <div class="row">

    <div class="col-12" v-if="packages.data.length < 1">
      <p class="text text_italic text_green">You have not created any packages yet. Please click the button to create a
        package.</p>
    </div>

    <div class="col-sm-12" v-else>
      <package-and-sets-filter :data-from-parent="packages.data"></package-and-sets-filter>
    </div>

    <div class="col-md-4 col-sm-6"
         v-for="(item, index) in dataItems"
         :key="item.id"
    >

      <div class="orderItem">
        <div class="orderItem__listItems">
          <h3 class="orderItem__listTitle" :title="item.name">{{item.name}}</h3>

          <p class="orderItem__dropListTitle text_green"
             v-if="item.options"
             @click="openList"
          >Items
            <span class="orderItem__arrow">
              <svg-icon name="chevron-left"/>
            </span>
          </p>
          <div class="orderItem__dropList">
            <p class="text text_default" v-for="i in item.item">{{i.name}}</p>
          </div>
          <div class="toggleList" v-if="item.options.length !== 0">
            <strong>Options:</strong>
            <ul class="">
              <li v-for="option in item.options"
                  :class="{'custom': option.custom}"
              >
                <svg-icon name="check-square" v-if="option.custom"/>
                {{option.name}}
              </li>
            </ul>
          </div>

          <div class="toggleList" v-if="item.accessories.length !== 0">
            <strong>Accessories:</strong>

            <ul class="">
              <li class=""
                  v-for="accessor in item.accessories"
                  :key="accessor.get_item.id"
              >
                {{accessor.get_item.name}}
              </li>
            </ul>
          </div>
        </div>

        <div class="orderItem__footer">
          <button class="btn btn_default" type="button" :disabled="!item.can_edit" @click="editPackage(item.id, index)">
            Edit
          </button>
          <button class="btn btn_default" type="button" :disabled="!item.can_edit" @click="clone(item)">Clone
          </button>
          <button class="btn btn_default" type="button" :disabled="!item.can_edit" @click="openDelModal(item.id)">
            Remove
          </button>
        </div>

      </div>
    </div>

    <div class="col-md-4 col-sm-6 d-flex justify-content-center align-items-center">
      <div class="thumbnailsTable" @click="nextStep">
        <span class="thumbnailsTable__img">
          <svg-icon name="plus-circle" class="large_icon"/>
        </span>
        <span class="thumbnailsTable__text">Create New Package</span>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div class="modal modal_wrap" v-if="selectModal">
        <div class="modal_popup modal_full">

          <select-type :createdOrder="false"
                       v-if="currentRouteName === 'package-builder'"
                       class="modal_content"></select-type>
          <select-style
            v-else-if="currentRouteName === 'package-builder-type'"
            class="modal_content"
            :checkBox="true"
          ></select-style>

          <select-grade
            v-else-if="currentRouteName === 'package-builder-style'"
            class="modal_content"
            :checkBox="true"
          ></select-grade>

          <select-options-to-package
            v-else-if="currentRouteName === 'package-builder-grade' || currentRouteName === 'package-builder-option'"
            class="modal_content"
            :checkBox="true"
            :package="true"
          ></select-options-to-package>

          <button class="btn btn_transparent" @click="openWarning"><svg-icon name="solid/times" /> Cancel</button>

        </div>
      </div>
    </transition>

    <transition name="fade">
      <warning @close="closeSelect" v-if="warningModal" tabindex="0"/>
    </transition>

    <transition name="fade">
      <delete @delete="openDelModal" tabindex="0" v-if="show" :id="packageId" :package="true"/>
    </transition>

    <transition name="fade">
      <clone tabindex="0" v-if="showClone" :id="packageId" :oldName="packageName" :package="true"/>
    </transition>
  </div>

</template>

<script>

  import router from "../../router";
  import {mapGetters} from 'vuex';
  import {defineAsyncComponent} from "vue";
  const SelectType = defineAsyncComponent(() => import('../orders/SelectType'));
  const SelectStyle = defineAsyncComponent(() => import('../orders/SelectStyle'));
  const SelectGrade = defineAsyncComponent(() => import('../orders/SelectGrade'));
  const SelectColors = defineAsyncComponent(() => import('../orders/SelectColors'));
  const SelectOptionsToPackage = defineAsyncComponent(() => import('../orders/SelectOptionsToPackage'));
  const PackageAndSetsFilter = defineAsyncComponent(() => import('./PackageAndSetsFilter'));

  const Warning = defineAsyncComponent(() => import('../modals/WarningModal'));
  const Delete = defineAsyncComponent(() => import('../modals/DeleteModal'));
  const Clone = defineAsyncComponent(() => import('../modals/ClonePackage'));



  export default {
    name: "Packages",
    components: {
      SelectType,
      SelectStyle,
      SelectGrade,
      SelectColors,
      SelectOptionsToPackage,
      PackageAndSetsFilter,
      Warning,
      Delete,
      Clone
    },

    data() {
      return {
        modals: {
          selectType: false,
          selectStyle: false,
          selectGrade: false,
          selectColors: false,
          selectOptions: false,
        },
        exchangeData: '',
        currentRouteName: '',
        dataItems: [],
        packageId: 0,
        packageName: '',
        show: false,
      }
    },

    computed: {

      ...mapGetters(
        {
          IsEdit: 'packages/IsEdit',
          selectModal: 'createdOrder/selectModal',
          packages: 'packages/packages',
          showClone: 'packages/cloneModal',
          warningModal: 'createdOrder/warningModal',
        }
      )
    },

    methods: {
      setRoute() {
        this.currentRouteName = this.$router.currentRoute.name;
      },

      nextStep() {
        this.$store.commit('createdOrder/selectModal', true);
        this.$store.commit('packages/setIsEdit', false);
        this.$store.commit('packages/setIsEditFromPackage', true);

        this.$store.commit(
          'app/set',
          {
            k: 'breadcrumbs',
            v: [{
              route: {
                path: '/package-builder'
              },
              name: 'Enter Package Name',
            }]
          }
        );
        router.push({path: '/package-builder'})
      },

      openWarning() {
        this.$store.commit('createdOrder/warningModal', true);
      },

      closeSelect() {
        this.$store.commit('createdOrder/selectModal', false);
      },

      openDelModal(id) {
        this.show = true;
        this.packageId = id;
        this.$store.commit('createdOrder/deleteModal', true);
      },

      editPackage(id) {
        this.$store.dispatch('packages/prepareUpdatePackage', id);
        this.$store.commit('packages/setIsEditFromPackage', true);
        this.$store.commit('packages/setIsEdit', true);
      },

      clone(item) {
        this.packageId = item.id;
        this.packageName = item.name;
        this.$store.commit('packages/setClone', true);
      },

      checkRoute() {
        if (this.$router.name !== 'packages' && !this.show) {
          this.$router.push({name: 'packages'})
        }
      },

      openList(){
        let tar = event.target;
        tar.closest('.orderItem').classList.toggle('orderItem_open');
      },

    },

    watch: {
      packages(n) {
        this.dataItems = n.data;
      },
      exchangeData() {

        _.forEach(this.modals, (v, k) => {
          this.modals[k] = false
        });

        this.modals[this.exchangeData] = true
      },
      '$route'(to, from) {

        let oldPath = from.path,
          newPath = to.path,
          oldRoute = _.split(_.trimStart(oldPath, '/'), '/'),
          newRoute = _.split(_.trimStart(newPath, '/'), '/');

        if (newRoute[0] === 'package-builder') {
          if (newRoute.length < oldRoute.length) {
            this.$store.dispatch('app/popBreadcrumb');
          }
        }
        this.currentRouteName = to.name;
      }
    },

    created() {
      this.setRoute();
      this.checkRoute();
      this.$store.dispatch('packages/getPackages');
    },

    beforeDestroy() {
      this.$store.commit('packages/setIsEditFromPackage', false);
    }

  }
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  .orderItem {

    &__listTitle {
      color: $green;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__footer {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .btn {
        flex: 1 1 calc(33.3% - 10px);
        margin: 0 5px 10px;
        padding: .5rem .3rem;
        display: block;
      }
    }

    ul {
      li {
        list-style: disc inside;
        display: list-item;
        &.custom {
          list-style-type: none;
          svg {
            color: $green;
          }
        }
      }
    }
  }

  .toggleList {
    margin-top: 10px;
  }

  .thumbnailsTable {
    padding: 0 1rem;
    cursor: pointer;
  }

  .orderItem__listItems {
    max-height: 215px;
    overflow: auto;
  }


</style>

<style lang="scss">
  /*.breadcrumbs__item_disabled {*/
    /*display: block;*/
  /*}*/
</style>
